
const mySwiper = new Swiper('.swiper', {
  loop: false,
  effect: 'fade',
  speed: 2000,
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
    stopOnLastSlide: true,
  }
});